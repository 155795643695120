import React, { FunctionComponent } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Category } from "../../models/category"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"
import * as S from "./category-list.style"

const CategoryList: FunctionComponent = () => {
  const categoriesQuery = useStaticQuery<{
    allCategories: { nodes: Category[] }
  }>(graphql`
    query Categories {
      allCategories(filter: { featured: { eq: true } }) {
        nodes {
          name
          icon {
            childImageSharp {
              gatsbyImageData(height: 55)
            }
            extension
            publicURL
          }
        }
      }
    }
  `)
  const categories = categoriesQuery.allCategories.nodes

  return (
    <S.CategoryContainer>
      <S.CategoryListTitle>Categorias em destaque</S.CategoryListTitle>
      <S.StyledCategoryList>
        {categories.map((category, index) => {
          const icon = category.icon
          return (
            <S.StyledCategory key={index}>
              <Link
                to={`/categoria/${slugify(category.name, { lower: true })}`}
              >
                {icon.extension !== "svg" ? (
                  <GatsbyImage
                    image={getImage(category.icon)}
                    alt={category.name}
                  />
                ) : (
                  <S.CategoryIcon src={icon.publicURL} alt={category.name} />
                )}
                <S.CategoryName>{category.name}</S.CategoryName>
              </Link>
            </S.StyledCategory>
          )
        })}
      </S.StyledCategoryList>
      <S.CategoryArchiveLinkWrapper>
        <S.CategoryArchiveLink to={`/categorias`}>
          Ver todas as categorias
        </S.CategoryArchiveLink>
      </S.CategoryArchiveLinkWrapper>
    </S.CategoryContainer>
  )
}

export default CategoryList
