import React from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo/seo"
import * as S from "./../components/pages/index/index.style"
import CategoryList from "../components/category-list/category-list"
import { Card } from "../components/card/card"
import { getImage } from "gatsby-plugin-image"
import SidebarContent from "../components/sidebar-content/sidebar-content"
import { Post } from "../models/post"

const IndexPage = ({ data, location }) => {
  const posts: Post[] = data.posts.edges.map(node => node.node)

  return (
    <Layout>
      <SEO location={location} type={`WebSite`} />
      <S.HomeContainer>
        <S.PostsContainer>
          {posts.map((post, index) => (
            <Card
              title={post.frontmatter.title}
              path={post.frontmatter.path}
              featuredImageAlt={post.frontmatter.featuredImageAlt}
              featuredImage={
                post.frontmatter?.featuredImage
                  ? getImage(post.frontmatter.featuredImage)
                  : null
              }
              content={post.frontmatter.description}
              key={index}
              meta={{
                time: post.frontmatter.createdDate,
                timePretty: post.frontmatter.createdDatePretty,
                category:
                  post.frontmatter.categories.length > 0
                    ? post.frontmatter.categories[0]
                    : null,
              }}
              style={{ gridArea: index === 0 ? "latest" : undefined }}
              halfImage={index === 0}
            />
          ))}
          <S.ArchiveLinkWrapper>
            <S.ArchiveLink to={`/posts`}>Todos os Posts</S.ArchiveLink>
          </S.ArchiveLinkWrapper>
        </S.PostsContainer>
        <S.Sidebar>
          <SidebarContent />
        </S.Sidebar>
      </S.HomeContainer>
      <CategoryList />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { fields: frontmatter___createdDate, order: DESC }
    ) {
      edges {
        node {
          id
          headings {
            depth
          }
          frontmatter {
            title
            path
            categories
            description
            createdDate
            createdDatePretty: createdDate(
              formatString: "DD MMMM, YYYY"
              locale: "pt-br"
            )
            updatedDate
            updatedDatePretty: updatedDate(
              formatString: "DD MMMM, YYYY"
              locale: "pt-br"
            )
            featuredImageAlt
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [WEBP]
                  quality: 40
                )
              }
            }
          }
        }
      }
    }
  }
`
