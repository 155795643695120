import React, { CSSProperties, FunctionComponent, ReactNode } from "react"
import * as S from "./card.style"

export interface CardProps {
  title?: string
  path: string
  featuredImage?: any
  featuredImageAlt?: string
  content?: string
  meta?: {
    time: string
    timePretty: string
    category: string | null
  }
  halfImage?: boolean
  compact?: boolean
  style?: CSSProperties
  children?: ReactNode
}

export const Card: FunctionComponent<CardProps> = ({
  title,
  meta,
  path,
  featuredImage,
  featuredImageAlt,
  content,
  halfImage = false,
  compact = false,
  style,
  children,
}) => (
  <S.StyledArticle style={style}>
    <S.StyledCard to={path}>
      {featuredImage && (
        <S.FeaturedImage
          image={featuredImage}
          $halfImage={halfImage}
          alt={featuredImageAlt}
        />
      )}
      <S.CardContent compact={compact}>
        {children}
        <header>
          {meta && (
            <S.CardMeta>
              {meta.category && <>{meta.category}</>}
              {meta.time && <time dateTime={meta.time}>{meta.timePretty}</time>}
            </S.CardMeta>
          )}
          {title && <S.CardTitle>{title}</S.CardTitle>}
        </header>
        {content && <p dangerouslySetInnerHTML={{ __html: content }} />}
      </S.CardContent>
    </S.StyledCard>
  </S.StyledArticle>
)
